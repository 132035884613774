<template>
  <div>
    <check-verified-profile />
    <n-grid cols="1 s:6" item-responsive responsive="screen" class="pb-2 pt-2"
      ><n-gi span="1 s:4" offset="0 s:1" style="text-align: end">
        <span v-if="rent?.state !== 'ARBITRATION_IN_PROCESS'"
          ><n-dropdown trigger="click" :options="actions" placement="bottom-end"
            ><n-button>{{ t("commons.labels.action", 2) }}</n-button></n-dropdown
          ></span
        >
      </n-gi></n-grid
    >
    <n-grid cols="1 s:6" responsive="screen" style="padding-top: 30px"
      ><n-gi span="1 s:4" offset="0 s:1"> <Header v-if="rent" :rent="rent" /></n-gi
      ><n-gi span="1 s:4" offset="0 s:1">
        <about-user
          v-if="reviewUserId"
          :userId="reviewUserId"
          :userType="reviewUserType"
        /><rent-resumen
          v-if="rent"
          :publicationTitle="rent.publication.title"
          :reservedDates="rent.requestedRangeOfDates"
          :executedDates="rent.executedRangeOfDates"
          :deliveryInfo="rent.deliveryInfo"
          fromMaintainer
          fullscreen
        />
        <price-details v-if="rent" :rent="rent" fromMaintainer fullscreen /> </n-gi
    ></n-grid>
  </div>
</template>

<script>
import { onMounted, computed, onBeforeUnmount, h, ref } from "vue";
import { useStore } from "vuex";
import RentResumen from "@/components/rent/Resumen.vue";
import { NGrid, NGi, NDropdown, NButton, NIcon, useDialog, useMessage } from "naive-ui";
import { useRouter } from "vue-router";
import PriceDetails from "@/components/rent/PriceDetails.vue";
import CheckVerifiedProfile from "@/components/myProfile/CheckVerifiedProfile.vue";
import Header from "@/components/rent/Header.vue";
import {
  ChatboxOutline as ChatIcon,
  CheckmarkCircleOutline as DeliveryCheckListIcon,
  CheckmarkDoneCircleOutline as ReturnCheckListIcon,
  CalendarOutline as ChangeDatesIcon,
  CloseCircleOutline as CancelIcon,
} from "@vicons/ionicons5";
import {
  Money as PayIcon,
  FolderAdd as CreateIssue,
  Review as ReviewIcon,
} from "@vicons/carbon";
import CancelationDetails from "@/components/rent/CancelationDetails.vue";
import { differenceInCalendarDays } from "date-fns";
import AboutUser from "@/components/AboutUser.vue";
import { useI18n } from "vue-i18n";

const renderIcon = (icon) => {
  return () => {
    return h(NIcon, null, {
      default: () => h(icon),
    });
  };
};

export default {
  props: {
    id: { type: String, required: true },
  },
  components: {
    RentResumen,
    NGrid,
    NGi,
    Header,
    PriceDetails,
    NDropdown,
    NButton,
    AboutUser,
    CheckVerifiedProfile,
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const router = useRouter();
    const rentRef = computed(() => store.state.maintainer_rents.detail);
    const dialog = useDialog();
    const message = useMessage();
    const currentUserRef = computed(() => store.getters["auth/user_id"]);

    const reviewUserTypeRef = ref(null);
    const reviewUserIdRef = ref(null);

    onMounted(async () => {
      await store.dispatch("app/lockUI");
      await store.dispatch("maintainer_rents/loadDetail", {
        id: props.id,
        userId: currentUserRef.value,
      });

      reviewUserTypeRef.value =
        currentUserRef.value === rentRef.value.user
          ? t("commons.persons.renter")
          : t("commons.persons.owner");

      reviewUserIdRef.value =
        reviewUserTypeRef.value === t("commons.persons.owner")
          ? rentRef.value.publication.user
          : rentRef.value.user;

      if (
        rentRef.value.state === "IN_PROCESS" ||
        rentRef.value.state === "PENDING_FOR_PAY_EXTRA_DAYS"
      ) {
        await store.dispatch("maintainer_rents/refreshTotals");
      }

      await store.dispatch("app/unlockUI");
    });
    onBeforeUnmount(() => {
      store.dispatch("maintainer_rents/unloadDetail");
    });

    const canCancel = computed(() => {
      if (rentRef.value.state !== "PENDING") {
        return false;
      }

      return true;
    });

    const canChangeDates = computed(() => {
      if (rentRef.value.state === "IN_PROGRESS" || rentRef.value.state === "PENDING") {
        return true;
      }

      return false;
    });

    const isCancelationFree = async () => {
      const daysBeforeStartRent = differenceInCalendarDays(
        rentRef.value.requestedRangeOfDates.start,
        new Date()
      );

      if (
        daysBeforeStartRent === 0 &&
        (await store.dispatch("rent/isOtherRentInProcess", {
          rentId: rentRef.value.id,
          publicationId: rentRef.value.publication.id,
        }))
      ) {
        return true;
      }

      return await store.dispatch("rent/isCancelationFree", {
        rent: rentRef.value,
        publication: rentRef.value.publication,
      });
    };

    return {
      t,
      rent: rentRef,
      reviewUserType: reviewUserTypeRef,
      reviewUserId: reviewUserIdRef,
      goToPublication(id) {
        router.push({
          name: "view-publication",
          params: { publicationId: id },
        });
      },
      actions: computed(() => {
        const actions = [
          {
            label: t("commons.actions.create", {
              reference: t("commons.labels.issue"),
            }),
            key: "createIssue",
            icon: renderIcon(CreateIssue),
            props: {
              onClick: () => {
                router.push({
                  name: "issue-create",
                  params: { rentId: props.id },
                });
              },
            },
          },
        ];

        if (rentRef.value?.user !== store.getters["auth/user_id"]) {
          if (rentRef.value?.state === "PENDING") {
            actions.push({
              label: t("commons.actions.check", {
                reference: t("commons.labels.delivery"),
              }),
              key: "deliver",
              icon: renderIcon(DeliveryCheckListIcon),
              props: {
                onClick: () => {
                  router.push({
                    name: "checklist",
                    params: {
                      rentId: rentRef.value?.id,
                      checklistType: "DELIVERY",
                    },
                  });
                },
              },
            });
          }
          if (
            rentRef.value?.state === "IN_PROCESS" ||
            rentRef.value?.state === "RETURNED"
          ) {
            actions.push({
              label:
                rentRef.value?.state === "IN_PROCESS"
                  ? t("commons.actions.check", {
                      reference: t("commons.labels.return"),
                    })
                  : t("commons.actions.registered", {
                      reference: t("commons.labels.checklist"),
                    }),
              key: "return",
              icon: renderIcon(ReturnCheckListIcon),
              props: {
                onClick: () => {
                  router.push({
                    name: "checklist",
                    params: {
                      rentId: rentRef.value?.id,
                      checklistType: "RETURN",
                    },
                  });
                },
              },
            });
          }
        } else {
          if (canChangeDates.value) {
            actions.push({
              label: t("commons.actions.change", {
                reference: t("commons.labels.date", 2),
              }),
              key: "change-dates",
              icon: renderIcon(ChangeDatesIcon),
              props: {
                onClick: () => {
                  router.push({
                    name: "rent",
                    params: {
                      publicationId: rentRef.value.publication.id,
                      rentId: rentRef.value.id,
                    },
                  });
                },
              },
            });
          }
          if (
            rentRef.value?.state === "PENDING_FOR_PAY_EXTRA_DAYS" ||
            rentRef.value?.state === "PENDING_FOR_CHANGE_PAYMENT_METHOD" ||
            rentRef.value?.state === "DEPOSIT_PENDING"
          ) {
            actions.push({
              label: t("commons.actions.pay"),
              key: "pay",
              icon: renderIcon(PayIcon),
              props: {
                onClick: () => {
                  router.push({
                    name: "rent",
                    params: {
                      publicationId: rentRef.value.publication.id,
                      rentId: rentRef.value.id,
                    },
                  });
                },
              },
            });
          }

          if (canCancel.value) {
            actions.push({
              label: t("commons.actions.cancel"),
              key: "cancel",
              icon: renderIcon(CancelIcon),
              props: {
                onClick: async () => {
                  const cancelationFree = await isCancelationFree();
                  console.debug("isCancelationFree", cancelationFree);
                  const d = dialog.warning({
                    title: t("commons.labels.cancelation"),
                    content: () =>
                      h(CancelationDetails, {
                        payed: rentRef.value.totalPayed,
                        refundAmount: cancelationFree ? rentRef.value.totalPayed : 0,
                      }),
                    positiveText: t("commons.confirmation.yes"),
                    negativeText: t("commons.confirmation.no"),
                    onPositiveClick: () => {
                      d.loading = true;
                      d.closable = false;
                      d.maskClosable = false;
                      return new Promise((resolve) => {
                        store
                          .dispatch("maintainer_rents/cancelRent", cancelationFree)
                          .then(() => {
                            //Enviamos notificacion al owner
                            store.dispatch("notifications/FireNotification", {
                              userId: rentRef.value.publication.user,
                              fromUserId: rentRef.value.publication.user,
                              title: t("commons.actions.canceled", {
                                reference: t("commons.labels.rent"),
                              }),
                              message: t(
                                "rent.cancelation.dialogs.cancelationConfirmation.content",
                                {
                                  publicationTitle: rentRef.value.publication.title,
                                }
                              ),
                              avatar: {
                                publicationID: rentRef.value.publication.id,
                                publicationUser: rentRef.value.publication.user,
                                defaultImage: rentRef.value.publication.orderPhotos[0],
                              },
                              targetRoute: {
                                name: "view-rent-detail",
                                params: [
                                  {
                                    key: "id",
                                    value: rentRef.value.id,
                                  },
                                ],
                              },
                            });
                            message.success(
                              t("commons.actions.canceled", {
                                reference: t("commons.labels.rent"),
                              })
                            );
                            router.push({ name: "my-rents" });
                          })
                          .then(resolve)
                          .catch((error) => {
                            console.error(error);
                            message.error(t("rent.cancelation.messages.errorCanceling"));
                            dialog.destroyAll();
                          });
                      });
                    },
                  });
                },
              },
            });
          }
        }

        if (rentRef.value?.state === "FINISHED" || rentRef.value?.state === "RETURNED") {
          actions.push({
            label: t("commons.labels.review", 1),
            key: "review",
            icon: renderIcon(ReviewIcon),
            props: {
              onClick: () => {
                router.push({
                  name: "review",
                  params: { rentId: rentRef.value?.id },
                });
              },
            },
          });
        }

        if (
          rentRef.value?.state === "PENDING" ||
          rentRef.value?.state === "IN_PROCESS" ||
          rentRef.value?.state === "PENDING_FOR_PAY_EXTRA_DAYS" ||
          rentRef.value?.state === "PENDING_FOR_CHANGE_PAYMENT_METHOD" ||
          rentRef.value?.state === "DEPOSIT_PENDING"
        ) {
          actions.push({
            label: t("commons.labels.chat"),
            key: "chat",
            icon: renderIcon(ChatIcon),
            props: {
              onClick: () => {
                router.push({
                  name: "rent-chat",
                  params: { id: props.id },
                });
              },
            },
          });
        }
        return actions;
      }),
    };
  },
};
</script>
