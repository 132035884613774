<template>
  <n-divider v-if="!loading" title-placement="left">
    {{ t(`commons.persons.${userType.toLowerCase()}`) }}
  </n-divider>
  <n-thing v-if="!loading">
    <template #avatar>
      <n-avatar
        :style="{
          backgroundColor: 'white',
        }"
        :size="48"
      >
        <n-icon :color="themeOverrides.common.primaryColor">
          <PersonCircleOutline />
        </n-icon>
      </n-avatar>
    </template>
    <template #header> {{ user ? user.name : "" }} </template>
    <template #header-extra>{{
      t("aboutUser.labels.joinedIn", { date: user ? formatDate(user.createdAt) : "" })
    }}</template>
    <template #description v-if="reviews.length > 0">
      <n-button text @click="showModal = true">
        <template #icon>
          <n-icon>
            <Star />
          </n-icon>
        </template>
        {{ rate }} ({{ reviews.length }}
        {{ t("aboutUser.labels.review", reviews.length) }})
      </n-button>
    </template>
  </n-thing>
  <ModalReviews :reviews="reviews" :users="users" v-model:show="showModal" />
</template>

<script>
import { defineComponent, onMounted, ref, computed } from "vue";
import { NButton, NAvatar, NIcon, NThing, NDivider } from "naive-ui";
import responsive from "@/mixins/responsive";
import { PersonCircleOutline, Star } from "@vicons/ionicons5";
import { round } from "@/shared/utils";
import { useStore } from "vuex";
import { format, parseISO } from "date-fns";
import ModalReviews from "@/components/ModalReviews.vue";
import { themeOverrides } from "@/shared/constants";
import { useI18n } from "vue-i18n";

const DATE_FORMAT = "MMM yyyy";

export default defineComponent({
  name: "AboutUser",
  props: {
    userId: { type: String, required: true },
    bordered: { type: Boolean, required: false, default: false },
    vertical: { type: Boolean, required: false, default: false },
    userType: {
      type: String,
      required: true,
      validator(value) {
        return ["Renter", "Owner"].includes(value);
      },
    },
  },
  components: {
    NButton,
    NAvatar,
    NIcon,
    PersonCircleOutline,
    Star,
    NThing,
    NDivider,
    ModalReviews,
  },
  mixins: [responsive],
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    //Funciones compartidas
    const store = useStore();
    //Controles UI
    const loadingRef = ref(false);
    const showModalRef = ref(false);
    //Referencias
    const usersRef = ref([]);
    const reviewsRef = ref([]);
    //Funciones internas
    const loadReviews = async () => {
      reviewsRef.value = await store.dispatch("review/getReviewsTo", props.userId);
      reviewsRef.value.sort((a, b) => {
        if (a.userRate > b.userRate) {
          return 1;
        }
        if (a.userRate < b.userRate) {
          return -1;
        }
        return 0;
      });
    };

    const loadUsers = async () => {
      const usersId = [props.userId];

      for (const review of reviewsRef.value) {
        if (!usersId.includes(review.from)) {
          usersId.push(review.from);
        }
      }

      usersRef.value = await store.dispatch("review/getUsersById", usersId);
    };

    onMounted(async () => {
      loadingRef.value = true;
      await loadReviews();
      await loadUsers();
      loadingRef.value = false;
    });

    return {
      t,
      themeOverrides,
      user: computed(() => usersRef.value.find((user) => user.id === props.userId)),
      getUser: (id) => usersRef.value.find((user) => user.id === id),
      reviews: reviewsRef,
      users: usersRef,
      showModal: showModalRef,
      loading: loadingRef,
      rate: computed(() =>
        round(
          reviewsRef.value.reduce((partial, review) => partial + review.userRate, 0) /
            reviewsRef.value.length
        )
      ),
      formatDate: (date) => format(parseISO(date), DATE_FORMAT),
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(.n-thing-avatar) {
  margin-right: 5px !important;
}
:deep(.n-thing-header) {
  margin-bottom: 0px !important;
}
:deep(.n-thing-main__description) {
  text-align: start;
  margin: -5px -5px -5px 0px;
}
:deep(.n-thing-main__content) {
  text-align: start;
}
.n-divider:not(.n-divider--vertical) {
  margin-bottom: 0px !important;
}
</style>
