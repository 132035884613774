<template>
  <span v-if="!refundAmountFormatted">
    <br />
    {{ t("rent.cancelation.notificationOfCharges") }}
  </span>
  <n-alert type="warning" :show-icon="false" v-else>
    <n-divider title-placement="left" class="mt-0">
      {{ t("rent.cancelation.details.title") }}</n-divider
    >
    <n-grid :cols="fullscreen ? '1' : '1 s:3'" responsive="screen"
      ><n-gi span="1" offset="0 s:1">
        <n-space justify="space-between">
          <span>{{ t("rent.cancelation.details.labels.payed") }}</span>
          <span>{{ payedFormatted }}</span>
        </n-space>
        <n-space justify="space-between">
          <span>{{ t("rent.cancelation.details.labels.fee") }}</span>
          <span v-if="cancelationFeeFormatted">-{{ cancelationFeeFormatted }}</span
          ><span v-else>{{ t("commons.labels.free") }}</span>
        </n-space>
        <n-space justify="space-between" v-if="taxFormatted">
          <span>{{ t("rent.cancelation.details.labels.tax") }}</span>
          <span>-{{ taxFormatted }}</span>
        </n-space>
        <n-divider />
        <n-space justify="space-between">
          <span>{{ t("rent.cancelation.details.labels.refundAmount") }}</span>
          <span>{{ refundAmountFormatted }}</span>
        </n-space>
      </n-gi></n-grid
    >
  </n-alert>
  <br />
  <br />
  {{ t("rent.cancelation.confirmationMessage") }}
</template>
<script>
import { defineComponent, onMounted, toRefs, computed } from "vue";
import responsive from "@/mixins/responsive";
import { NDivider, NGrid, NGi, NSpace, NAlert } from "naive-ui";
import { floatToCurrency } from "@/shared/utils";
import { useI18n } from "vue-i18n";

export default defineComponent({
  mixins: [responsive],
  name: "CancelationDetails",
  components: { NDivider, NGrid, NGi, NSpace, NAlert },
  props: {
    fullscreen: { type: Boolean, default: true },
    payed: { type: Number, default: 0 },
    cancelationFee: { type: Number, default: 0 },
    tax: { type: Number, default: 0 },
    refundAmount: { type: Number, default: 0 },
  },
  emits: ["onmounted"],
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const {
      payed: payedRef,
      cancelationFee: cancelationFeeRef,
      tax: taxRef,
      refundAmount: refundAmountRef,
    } = toRefs(props);
    onMounted(() => {});

    return {
      t,
      payedFormatted: computed(() => floatToCurrency(payedRef.value)),
      cancelationFeeFormatted: computed(() =>
        cancelationFeeRef.value > 0 ? floatToCurrency(cancelationFeeRef.value) : null
      ),
      taxFormatted: computed(() =>
        taxRef.value > 0 ? floatToCurrency(taxRef.value) : null
      ),
      refundAmountFormatted: computed(() =>
        refundAmountRef.value > 0 ? floatToCurrency(refundAmountRef.value) : null
      ),
    };
  },
});
</script>

<style lang="scss" scoped>
.n-text.n-text--strong {
  font-weight: 550;
}
</style>
